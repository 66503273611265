.MuiFilledInput-root {
  background-color: #f7f7fc;
  overflow: hidden;
  border-radius: 4px 4px 0 0;
  color: #373a46;
  border: 0;
  font-weight: 600;
  border-bottom: 1px solid #e6e6e6;
}

.MuiFilledInput-root.Mui-focused {
  background-color: #e8edf7;
  border: 0;
  border-bottom: 1px solid #373a46;
  outline: 0;
  box-shadow: 0px;
}

.MuiFormLabel-root {
  color: #919598;
}

.MuiFormLabel-root.Mui-focused {
  color: #373a46;
}

.MuiMenuItem-root {
  color: #373a46;
  font-weight: 500;
  font-size: small;
}

.container {
  margin: 0;
  width: 100%;
}

.field-label {
  font-size: medium;
  font-weight: 600;
  color: #373a46;
}

.error-label {
  font-size: x-small;
  font-weight: 500;
}
