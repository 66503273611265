.MuiBackdrop-root {
  background-color: #00000030;
  outline: none;
}

.modal {
  background-color: #00000030;
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  outline: none;
}

.parent-container {
  outline: none;
  border: 1px solid #373a46;
  border-radius: 10px;
  background-color: white;
  width: 60%;
  padding: 10%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.modal-title {
  font-weight: 600;
  font-size: large;
  word-wrap: break-word;
  text-align: center;
}

.buttons-container {
  display: flex;
  flex-direction: row;
  margin-top: 10%;
  width: 100%;
}
