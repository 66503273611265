.form-container {
  margin: 0 5% 0 5%;
}

.form_item_container {
  margin: 0;
  margin-top: 3%;
  width: 100%;
}

.form-input-field {
  margin-top: 25px;
}
