.MuiBackdrop-root {
  background-color: transparent;
}

#modal-popup {
  background-color: transparent;
}

.damage-container-popup {
  flex-direction: column;
  display: flex;
  position: absolute;
  z-index: 100;
}

.damage-container-popup:focus {
  outline: 0;
}

.damage-square-popup {
  height: 20px;
  width: 20px;
  position: absolute;
  background-color: #fff;
  transform: rotate(45deg);
}
