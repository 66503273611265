.container {
  /* display: flex;
  flex-direction: column;
  align-items: center; */
}

.horizontal_divider {
  height: 1px;
  background-color: #f7f8f9;
  border: 0;
}

.continue_button_container {
  width: 100%;
  align-items: center;
  justify-content: center;
  display: flex;
  margin-top: 15%;
}

.continue_button {
  width: 90%;
}

.continue_inspection_button_container {
  width: 100%;
  align-items: center;
  justify-content: center;
  display: flex;
  margin-top: 5%;
  margin-bottom: 5%;
}

.continue_inspection_button {
  width: 90%;
  position: relative;
  display: flex;
  align-items: center;
}

.button_left_fixed_icon {
  position: absolute;
  left: 10px;
  padding: 0;
  margin: 0;
  height: 16px;
  width: 16px;
  z-index: 10;
}

.button_right_fixed_icon {
  position: absolute;
  right: 10px;
  padding: 0;
  margin: 0;
  height: 16px;
  width: 16px;
  z-index: 10;
}

.button_icon {
  width: 100%;
  height: 100%;
}
