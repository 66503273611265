.container {
  margin: 0;
  width: 100%;
}

.field-label {
  font-size: medium;
  font-weight: 600;
  color: #373a46;
}

.error-label {
  font-size: x-small;
  font-weight: 500;
}
