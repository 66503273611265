.MuiBackdrop-root {
  background-color: white;
  outline: none;
}

.modal {
  background-color: white;
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  outline: none;
}

.parent-container {
  outline: none;
}

.catchy-line {
  font-weight: 500;
  font-size: medium;
  text-align: center;
  margin-top: 3%;
  color: #373a46;
}
