.camera-container {
  display: flex;
  flex-direction: row;
  justify-content: center;
  padding: 10px 5px 10px 5px;
  border: 1px solid #f1f3f6;
  margin: 2% 0 2% 0;
  cursor: pointer;
}

.image-container {
  display: flex;
  flex: 1.5;
  align-items: center;
  justify-content: center;
}

.modal-image {
  width: 80%;
}

.camera-selected-image {
  width: 100%;
  cursor: pointer;
}

.camera-image {
  width: 70%;
}

.button-text-container {
  display: flex;
  flex: 7;
  padding-left: 12px;
}

.button-text {
  font-size: small;
  font-weight: 500;
}

.end-button-container {
  display: flex;
  flex: 3.5;
  align-items: center;
  justify-content: flex-end;
  padding-right: 5px;
}

.end-button-text {
  font-size: small;
  color: #00357c;
}

.camera-error-message {
  font-size: smaller;
  font-weight: 500;
  color: #ea5a00;
  margin: 0;
  margin-top: 8px;
}

.camera-input-field {
  display: none;
}

.picker-type-container {
  background-color: #fff;
  padding: 5px 20px 5px 20px;
  border: 0.5px solid #44485b;
  border-radius: 10px;
  outline: 0;
  width: 120px;
}

.picker-type-container:focus {
  outline: 0;
}

.picker-camera {
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-bottom: 1px solid #44485b;
}

.picker-file-selector {
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
}
