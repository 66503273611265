.bottom-banner-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  position: sticky;
  bottom: 0;
  padding-top: 15px;
  background-color: white;
  z-index: 1000;
}

.bottom-banner-back-button-container {
  display: flex;
  flex: 5;
  margin: 0 2% 3% 2%;
}

.bottom-banner-next-button-container {
  display: flex;
  flex: 5;
  margin: 0 2% 3% 2%;
}
