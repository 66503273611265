.container {
  margin: 0;
  width: 100%;
  padding-top: 1%;
}

.field-label {
  font-size: medium;
  font-weight: 600;
  color: #373a46;
}
