.divider_container {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 5%;
}

.divider {
  width: 40%;
  height: 2px;
}

.divider_text {
  font-size: small;
  font-weight: 500;
}
