.header_parent_container {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.header_container {
  display: flex;
  flex-direction: row;
  position: relative;
  align-items: center;
  height: 100%;
}

.header_divider_container {
  position: relative;
  height: auto;
  margin: 0 5% 0 5%;
}

.header_divider {
  width: 1px;
  background-color: #d9d9d9;
}

.logo {
  width: 30vw;
  margin-right: 2%;
}

.header_description {
  color: #828282;
  font-weight: 500;
  font-size: x-small;
  text-align: left;
}
