.container {
  width: 100%;
}

.asset_owner_container {
  width: 90%;
  margin: 4% 5% 0 5%;
}

.search_rc_container {
  margin: 12% 0 10% 0;
}

.search_rc {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.search_chassis {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.search_chassis_container {
  margin: 10% 0 10% 0;
}

.button_left_fixed_icon {
  position: absolute;
  left: 10px;
  padding: 0;
  margin: 0;
  height: 16px;
  width: 16px;
  z-index: 10;
}

.button_right_fixed_icon {
  position: absolute;
  right: 10px;
  padding: 0;
  margin: 0;
  height: 16px;
  width: 16px;
  z-index: 10;
}

.button_icon {
  width: 100%;
  height: 100%;
}

.continue_button_container {
  width: 100%;
  align-items: center;
  justify-content: center;
  display: flex;
  margin-top: 15%;
}

.continue_button {
  width: 100%;
}

.continue_inspection_button_container {
  width: 100%;
  align-items: center;
  justify-content: center;
  display: flex;
  margin-top: 5%;
  padding-bottom: 5%;
}

.continue_inspection_button {
  width: 100%;
  position: relative;
  display: flex;
  align-items: center;
}

