.MuiFilledInput-root {
  background-color: #f7f7fc;
  overflow: hidden;
  border-radius: 4px 4px 0 0;
  color: #373a46;
  border: 0;
  font-weight: 600;
  border-bottom: 1px solid #e6e6e6;
}

.MuiFilledInput-root:focus-within {
  background-color: #e8edf7;
  border: 0;
  border-bottom: 1px solid #373a46;
  outline: 0;
  box-shadow: 0px;
}

.MuiFormLabel-root {
  color: #919598;
}

.MuiFormLabel-root.Mui-focused {
  color: #373a46;
}

.MuiFilledInput-root::after {
  border-bottom: 0px;
}

.MuiFilledInput-root::before {
  border-bottom: 0px;
}

.MuiFilledInput-root:hover {
  border-bottom: 0px;
}

.MuiFilledInput-root.Mui-error {
  background-color: #fef1f1 !important;
  border-bottom: 1px solid #ef3c3c;
}

.error-text {
  font-size: x-small;
  text-align: left;
  color: #ef3c3c;
  font-weight: 500;
}

.MuiFilledInput-root.Mui-disabled {
  background-color: #f7f8f9 !important;
  color: #97999f;
}

.MuiFilledInput-root.Mui-disabled::before {
  border: 0px;
}

.Mui-disabled {
  color: #97999f;
}

.MuiInputBase-input:in-range::-webkit-datetime-edit-year-field,
.MuiInputBase-input:in-range::-webkit-datetime-edit-month-field,
.MuiInputBase-input:in-range::-webkit-datetime-edit-day-field,
.MuiInputBase-input:in-range::-webkit-datetime-edit-text {
  color: transparent;
}
