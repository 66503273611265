.top-banner-container {
  display: flex;
  flex-direction: row;
  margin: 5% 4% 5% 4%;
  justify-content: space-between;
}

.top-banner-left-container {
  display: flex;
  flex-direction: column;
  flex: 10;
}

.top-banner-header {
  color: #292c3e;
  font-weight: 500;
  font-size: large;
  margin-bottom: 8px;
}

.top-banner-subtitle-container {
  display: flex;
  flex-direction: row;
}

.top-banner-subtitle {
  font-size: smaller;
  font-weight: 500;
  color: #919598;
  word-wrap: break-word;
}

.horizontal-divider {
  height: 100%;
  width: 2px;
  background-color: #919598;
  margin: 0 5px 0 5px;
}

.top-banner-right-container {
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
}

.top-banner-button {
  height: 28px;
  width: 28px;
}
