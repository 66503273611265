.container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.illustration_parent_container {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 30vh;
  width: 100%;
  margin-bottom: 10%;
}

.illustration_container {
  width: 90%;
  background-color: #f7f7fc;
  border-radius: 14px;
  height: 25vh;
  display: flex;
  align-items: center;
  justify-content: center;
}

.illustration {
  height: 18vh;
}

.google_container {
  margin-bottom: 5%;
}

.google_icon {
  position: absolute;
  left: 10px;
  width: 24px;
  height: 24px;
}

.divider_cont {
  width: 90%;
}

.login_button_container {
  width: 100%;
}

.input_button_container {
  display: flex;
  flex-direction: row;
  height: 100%;
  align-items: center;
  justify-content: space-between;
}

.vertical_divider {
  width: 1px;
  height: 35px;
  display: flex;
  margin: 0;
  padding: 0;
  outline: 0;
  position: absolute;
  left: 0;
}

.input_button_text {
  font-size: x-small;
  font-weight: 500;
  padding-left: 10%;
}
