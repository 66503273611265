.inspection_container {
  height: 100vh;
}

.status_page_container {
  width: 100%;
}

.status_item {
  height: 50px;
  display: flex;
  flex-direction: row;
}

.status_icon_container {
  display: flex;
  flex: 2;
  align-items: center;
  justify-content: center;
}

.status_icon {
  width: 20px;
  height: 20px;
}

.status_text {
  display: flex;
  flex: 8;
  align-items: center;
  justify-content: flex-start;
  padding-left: 2%;
}

.status_right_icon_container {
  display: flex;
  flex: 2;
  align-items: center;
  justify-content: center;
}

.form_container {
  padding-bottom: 5%;
}

.camera_form_container {
  padding-bottom: 5%;
}

.error_container {
  width: 100%;
}

.error_message {
  font-size: small;
  color: #f79d9d;
}

.error_message_status {
  font-size: small;
  color: #f79d9d;
  margin-left: 5%;
}
