.MuiBackdrop-root {
  background-color: transparent;
}

.damage-container {
  width: 92vw;
  background-color: #f7f7fc;
  flex-direction: column;
  display: flex;
  padding: 2% 4% 8% 4%;
  position: absolute;
  z-index: 100;
}

.damage-square {
  height: 20px;
  width: 20px;
  position: absolute;
  background-color: #f7f7fc;
  transform: rotate(45deg);
}

.damage-title-container {
}

.damage-title {
  color: #484e54;
  font-weight: 500;
  font-size: medium;
}

.damage-selector-container {
  display: flex;
  flex-direction: row;
  min-width: 100%;
}

.color-bar-container {
  height: 20px;
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.text-container {
  margin-top: 10px;
  height: 20px;
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.damage-bar {
  height: 20px;
  width: 100%;
}

.options-section {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
}

.low-bar {
  border-radius: 20px 0 0 20px;
}

.medium-bar {
}

.high-bar {
  border-radius: 0 20px 20px 0;
}

.text-field {
  margin: 0;
  margin-top: 15px;
  text-align: center;
  width: 100%;
}
