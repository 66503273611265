.overall_success_container {
  height: 100vh;
}

.submit_container {
  width: 90%;
  display: flex;
  flex-direction: column;
  margin: 0 5% 5% 5%;
  align-items: center;
  justify-content: center;
  padding-top: 50%;
}

.success_icon_container {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 5%;
}

.success_icon {
  width: 50px;
  height: 50px;
}

.success_title {
  width: 100%;
  text-align: center;
  font-weight: 500;
  font-size: large;
  margin-bottom: 3%;
  color: #373a46;
}

.success_subtitle {
  width: 100%;
  text-align: center;
  font-weight: normal;
  color: #373a46;
  margin-bottom: 10%;
}
