.loader {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 5px;
  animation: spin 2s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(360deg);
  }
  100% {
    transform: rotate(0deg);
  }
}
